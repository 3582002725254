.map {
  min-height: 300px;
}

.map-search {
  padding: 0 10px;
  border: none;
  background: white;
  display: block;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.298039) 0 1px 4px -1px;
  height: 34px;
  border-radius: 3px;
  width: 600px;
  max-width: 63%;
}

.pac-container{
  z-index: 2000 !important;
}