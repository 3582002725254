.pilot-logs{
  &__heading{
    margin-bottom: 10px;
    display:flex;
    justify-content: space-between;
  }
  &__list-item{
    flex-grow: 1;
    margin: 3px;
  }
  &__pagination{
    display: flex;
    &:last-of-type{
      margin-top:10px;
    }
    ul{
      margin: 0px;
    }
  }
  &__btn-group{
    display:flex;
    justify-content: center;
    align-items: center;
  }
  &__button{
    margin-left: 1px;

    &--active{
      background: @primary-color-dark;
    }
  }
  &__log-link{
    flex-grow: 1;
    text-decoration: none !important;
    color: #636b6f;
    &:hover{
      color: #636b6f;
    }
  }
  &__grid{
    display:flex;
    flex-wrap: wrap;
  }
  &__grid-item{

  }
}
@media (max-width: 767px){
  .pilot-logs__heading{
    flex-direction: column-reverse;
  }
  .pilot-logs__button{
    flex-grow:1;
  }
}