.primary-button, .info-button {

  padding: 10px 15px;
  color: white;
  display: flex;
  border-radius: 4px;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .badge{
    background: white;
    color: @primary-color;
  }
}

.primary-button{
  background: #3097D1;
  box-shadow: 0 3px 0 0 #1c5b7f;

  &:hover{
    background: @primary-color-dark;
  }
}
.info-button{

  background: @light-grey;
  box-shadow: 0 3px 0 0 @light-grey-dark;

  &:hover{
    background: @light-grey-dark;
  }
}