#map-show{
  width: 100%;
  height: 100%;
}

.map-header{
  display:flex;
  justify-content: space-between;
}
.map-header__title{
  margin-right:100px;
}
.map-header__filter-group{
  flex-grow: 1;
  display: flex;
}
.map-header__filter{
  margin-left:10px;
}

.map-log{

  &__header{
    padding-bottom:5px;
    margin-bottom:10px;
    font-size:20px;
  }
  &__body{

  }
  &__link{
    opacity: 1;
  }
  &__line{
    margin: 10px 0;
    height: 1px;
    background: #dedede;
  }
}