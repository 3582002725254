.display-models{
  display: flex;
  margin: 0 -5px 5px;
  flex-wrap: wrap;

  a{
    flex-grow: 1;
    text-align: center;

    &:hover{
      text-decoration: none;
    }
  }

  &__link{
    padding: 5px 10px;
    margin: 5px;
    color: white;
    cursor: pointer;
    background: @primary-color-light;
    border-radius: 4px;
    &:hover{
      background: @primary-color;
    }
  }
}