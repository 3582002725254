
.relevent-links{

  a:hover, a:active, a:visited, a:focus{
    text-decoration: none;
  }
  .modal-dialog{
    margin-top: 100px;
  }

  &__box{
    margin-bottom: 15px;
    padding: 10px 15px;
    border-left: 2px solid #3097d1;
    color: grey;
    background: #f7f7f7;

    &:hover{
      background: rgba(48, 151, 209, 0.48);
      color: white;
    }
  }

  &__box-title{
    font-size: 16px;
    font-weight: bold;
  }

  &__box-description{
    font-size: 14px;
  }
}