.list{
  &:before{
    content: '';
  }
  .panel-heading{
    display: flex;
    justify-content: space-between;
  }
}
.list__title{
  flex-grow: 1;
}
.list__item{
  position: relative;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:nth-child(odd){
    background: white;
  }
  &:hover{
    padding-left:9px;
    border-left: 3px solid @primary-color;
  }
  &--active{
    padding-left:9px;
    border-left: 3px solid @primary-color;
  }
  &--not-active{
    &:after{
      content: 'Ikke aktiv';
      position: absolute;
      width: 100%;
      color: white;
      height: 100%;
      background: rgba(0, 0, 0, 0.48);
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
  }
}
.list__textarea{
  display:flex;
  justify-content: space-between;
  flex-direction:column;
}
.list__pilot{
  margin: 0;
}
.list__item-title{
  padding: 0;
  margin: 0 0 10px 0;
}
.list__options-popover-content{
  display:flex;
}
.list__options{
  position: relative;
  display: flex;

  .popover{
    background: transparent;
    border: none;
    box-shadow: none;
    z-index: 100;
    .arrow{
      display:none;
    }
  }
  &--in{
    display:block;
  }
  &--out{
    display:none;
  }
}
.list__options-button{
  cursor: pointer;
  margin: 0 0 0 5px;
  font-size: 20px;
  color: white;
  width: 40px;
  height: 35px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
}
.list__enter-view{
  color: @concrete-dark;
  font-sizE: 30px;
  z-index: 1;
}
.list__exit-view{
  background: @panel-button-color;
  padding: 10px 15px;
}
.list__search-toggle{
  margin: -10px -15px -10px 15px;
  background: @panel-button-color;
  padding: 10px 15px;
  &:hover{
    background: @panel-button-color-dark;
  }
}
.list__options-toggle{
  background:@concrete;
  box-shadow: 0 3px 0 @concrete-dark;
  &:hover{
    transition: background 0.3s;
    background: @concrete-dark;
  }
}
.list__options-edit , .list__options-cancel, .list__options-save, .list__options-delete, .list__search-toggle{
  padding: 10px 15px;
  background: @panel-button-color;
  border-left: 1px solid white;
  cursor: pointer;
  transition: background 0.3s;
  &:hover{
    transition: background 0.3s;
    background: @panel-button-color-dark;
  }
}
.list__options-edit--disabled, .list__search-toggle--disabled{
  background: @concrete;
  cursor: auto;
  &:hover{
    background: @concrete;
  }
}
.list__options-delete{
  background: @red;
  &:hover{
    background: @red-dark;
  }
}
.list__options-cancel{
  background: @orange;
  &:hover{
    background: @orange-dark;
  }
}
.list__options-save{
  background: @green;
  &:hover{
    background: @green-dark;
  }
}
.list__options-view{
  background: @green;
  box-shadow: 0 3px 0 @green-dark;
  &:hover{
    transition: background 0.3s;
    background: @green-dark;
  }
}
.list__body{
  color: white;
  p{
    margin: 2px;
  }
}
.list__accordion{
  margin: 10px 0;
  color: black;

  .panel-heading{
    cursor: pointer;
  }
  .badge{
    background: white !important;
    color: @primary-color !important;
  }
}
.list__details{
  .flex-fill{
    flex-grow: 1;
  }
  .panel-heading, .panel-footer{
    padding: 0;
    display: flex;
    justify-content: space-between;
    a{
      color: white !important;
    }
  }
  .panel-footer{
    background-color: #3097D1;
    color: white;
  }
  &-title{
    flex-grow: 1;
    padding: 10px 15px;
  }

}
.list__extra-collapse{

}

@media (max-width: 767px){
  .list__extra-collapse{
    display:none;
  }
  .list__details{
    z-index: 10;
    position: fixed;
    top: 70px;
    width: 100vw;
    left: 100vw;
    overflow-y: scroll;
    height: 100vh;
    .panel-default{
      z-index: 0;
    }
    .panel-heading{
      display: flex;
      position: fixed;
      z-index: 500;
      top: 70px;
      width: 100%;
      /* MAGIC HAPPENS HERE */
      transform: translateZ(0);
      -moz-transform: translatez(0);
      -ms-transform: translatez(0);
      -o-transform: translatez(0);
      -webkit-transform: translateZ(0);
      -webkit-font-smoothing: antialiased;
    }
    .panel-body{
      //padding-top: 55px;
      z-index:1;
      padding-bottom: 200px;
      &:before{
        height: 50px;
      }
    }
  }
  .list{
    &:before{
      content: '';
      display: block;
      height: 45px;
    }
    .panel-heading{
      position: fixed;
      display: flex;
      justify-content: space-between;
      width: 100%;
      z-index: 10;
      top:70px;

    }
    .panel-heading + .panel-search{
    }
  }
}

@media (max-width: 769px) {
  .list__details{
    //left: 0 !important;
  }
}
