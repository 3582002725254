.multiselect__tag-icon:focus, .multiselect__tag-icon:hover{
  background: @primary-color-dark !important;
}
.multiselect__tag, .multiselect__option--highlight, .multiselect__option--highlight:after{
  background: @primary-color !important;
}
.multiselect__option--selected.multiselect__option--highlight.multiselect__option--highlight,.multiselect__option--selected.multiselect__option--highlight.multiselect__option--highlight:after{
  background: #ff6a6a !important;
}
.multiselect__option--selected:hover, .multiselect__option--selected:hover:after{
  background: #ff6a6a !important;
}
.multiselect__tags{
  border: 1px solid #ccd0d2 !important;
  border-radius: 5px;

}
.has-error .multiselect__tags {
  border-color: #a94442 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.multiselect__no-result{
  background: @primary-color;
  color: white;
  margin: -12px;
  padding: 12px;
}