.page-placeholder{
  padding: 100px 0;
  background: @primary-color;
  color: white;
  display:flex;
  justify-content:center;
  align-items:center;
  text-align: center;
  flex-direction: column;

  &__title{
    margin-bottom: 50px;

  }
  &__button{
    background-color:@panel-button-color !important;
    &:hover{
      background: @panel-button-color-dark !important;
    }
  }
}