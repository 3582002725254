// bootstrap variable overrides
@icon-font-path: "/fonts/";
// -----

@primary-color: #3097d1;
@red: #e74c3c;
@green: #27ae60;
@orange: #e7a742;
@midnight: #2c3e50;
@concrete: #95a5a6;
@panel-button-color: #60acd7;
@light-grey: #8eb4cb;

@primary-color-dark: darken(@primary-color, 20%);
@red-dark: darken(@red, 20%);
@green-dark: darken(@green, 20%);
@orange-dark: darken(@orange, 20%);
@midnight-dark: darken(@midnight, 20%);
@concrete-dark: darken(@concrete, 20%);
@panel-button-color-dark: darken(@panel-button-color, 20%);
@light-grey-dark: darken(@light-grey, 20%);

@primary-color-light: lighten(@primary-color, 10%);

.faded{
  opacity: 0.5;
}
#intercom-container{
  z-index: 1040 !important;
}

@media (max-width: 767px) {
  .container > .row{
    margin-top: -30px;
  }
  .no-padding-xs{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .col-keep-padding .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .panel, .panel-heading{
    border-radius: 0px;
  }
}