.logs-list{

}
.log-modal{
  &__extras{

  }
  &__copy-container{
  }
  &__copy-button{
    width: auto;
    text-align: center;
    color: white;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 10px 15px;
    background: @primary-color;
    box-shadow: 0 3px 0 @primary-color-dark;
    cursor: pointer;
    &:hover{
      background: @primary-color-dark;
    }
  }
}

@media (max-width: 767px){
  .log-modal__extras{
    display:none;
  }
}
