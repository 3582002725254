
.loading-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.50);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}