.map-overlay {
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.50);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 100px;
}