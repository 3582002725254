.panel-default > .panel-heading {
  background-color: @primary-color;
  color: white;
}

.panel-no-bg {
  background: transparent;
  border: none;

  & > .panel-footer {
    border: 1px solid #d3e0e9;
  }
}

