.home{
  &__greeting{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    margin-bottom:25px;
    text-align: center;
  }
  &__add-button, &__filter-button{
    //margin-bottom:20px;
    display: flex;
    justify-content: center;
  }
  &__calendar-info{

  }
  &__calendar-info-lg{
    display: block;
  }
  &__calendar-info-xs{
    display: none;
    text-align: center;
  }
  &__highlight-title{
    font-weight: bold;
    margin: 11px 0;
  }
  &__highlight-info{
    background: @primary-color;
    color: white;
    padding: 2px 5px;
    text-align: center;
    margin-bottom: 5px;
  }
  &__highlight-night{
    background: @midnight;
  }
  &__highlight-occurence{
    background: @orange;
  }
}

.home-box{
  border-radius: 4px;
  margin-bottom: 30px;
  background: @primary-color-light;
  padding:15px;
  color: white;
  display:flex;
  min-height: 200px;
  flex-direction: column;
  justify-content: space-between;

  &__header{
    display:flex;
    justify-content:space-between;
    border-bottom: 1px solid white;
  }
  &__header-title{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  &__header-span{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background:white;
    color: @primary-color;
    display: flex;
    justify-content:center;
    align-items:center;
  }
  &__body{
    p{
      margin-bottom: 5px;
    }
  }
  &__link{
    color: white;
    text-decoration: underline;
  }
  &__go-to{
    p{
      margin:0px;
    }
    margin-top: 10px;
    background: rgba(52, 73, 94, 0.45);
  }

}
@media (max-width: 767px){
  .home__add-button{
    margin-bottom: 20px;
  }
  .home__calendar-info-xs{
    display: block;
  }
  .home__calendar-info-lg{
    display: none;
  }
}