#spark-navbar-collapse > .nav > li {
  &:hover:after {
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    background: #3097D1;
    bottom: 0px;
  }
}

.nav--active:after {
  display: block;
  content: '';
  width: 100%;
  height: 3px;
  position: absolute;
  background: #3097D1;
  bottom: 0px;
}

@media (max-width: 991px) {

  .nav > li > a {
    padding: 10px;
  }

}

@media (max-width: 769px) {

  #spark-navbar-collapse {
    padding-bottom: 50px;
    margin: 0 -15px;

    ul .open ul.dropdown-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }

  #spark-navbar-collapse .nav > li {
    position: relative;
    display: block;
    border-bottom: 1px solid rgba(222, 222, 222, 0.53);
  }
}

@media (max-width: 769px) {
  .navbar-header {
    float: none;
  }

  .navbar-left, .navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collapse.in {
    display: block !important;
    overflow-y: auto !important;
    padding: inherit;
  }

}

.call-to-action {
  position: relative;
  display: block;
  float: left;
  padding-top: 10px;

  a {
    &:hover {
      text-decoration: none;
    }
  }
}
